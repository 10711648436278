import React from "react"
import { injectIntl, IntlProvider } from "gatsby-plugin-intl"

import Layout from "../components/site/layout"
import Seo from "../components/common/seo"
import { PageContent, Section } from "../components/common/layout"
import { getLocale, getPathName, messagesForLanguage } from "../utils/localized"

const NotFoundPage = injectIntl(() => (
  <Layout>
    <PageContent>
      <Section>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Section>
    </PageContent>
  </Layout>
))

export const Head = () => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo
        titleId="meta.notFoundPage.title"
        path={getPathName()}
        descriptionId="meta.description"
      />
    </IntlProvider>
  )
}

export default NotFoundPage
